/** Main url of website */
export const baseUrl = "https://www.homoeopathyforeverhospital.com/";

// next parts of u
/** Url for list of names of all treatment result images */
export const imgListUrl = "resource/treatment-results-list/imgList.json";
/** Url for top 6 images that will be shown in main web site */
export const imgListTop6Url = "resource/treatment-results-list/imgListTop6.json";

/** Directory path / prefix for treatment results */
export const imgDirPath = "resource/treatment-results/";



// Auth api section

export const authApiUrl = "https://aasgthfgya.execute-api.ap-south-1.amazonaws.com/auth-management"


export const homePagePath = "/upload-result-photos"