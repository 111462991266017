import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UploadResultPhotos from './components/UploadResultPhotos';
import SelectTopPhotos from './components/SelectTopPhotos';
import ArrangeResultPhotos from './components/ArrangeResultPhotos';
import Login from './components/Login';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />}></Route>
        <Route path='' element={<App />}>
          <Route path="arrange-result-photos" element={<ArrangeResultPhotos />}></Route>
          <Route path="upload-result-photos" element={<UploadResultPhotos />}></Route>
          <Route path="select-top-photos" element={<SelectTopPhotos />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
