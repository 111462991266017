import awsConfig from "../constants/awsConfig";
import { GetObjectCommand, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { Buffer } from "buffer";
import Cookies from 'universal-cookie';
import { GetCallerIdentityCommand, STSClient } from "@aws-sdk/client-sts";
import isProdEnv from "../constants/isProdEnv";


/** To create S3 client
 * @returns {S3Client}
 */
function createS3Client() {
    /** @type {import("@aws-sdk/client-s3").S3ClientConfig} */
    const s3ConfigOptions = {
        credentials: generateAwsCredential(),
        region: awsConfig.region
    }

    return new S3Client(s3ConfigOptions)
}

/** To upload files to S3 bucket
 * @param {S3Client} s3Client
 * @param {File} file 
 * @param {string} fileName 
 */
export async function uploadToS3Core(s3Client, file, fileName = undefined) {
    /** 
     * @type {import("@aws-sdk/client-s3").PutObjectCommandInput}*/
    const uploadParams = {
        // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-s3/modules/putobjectrequest.html#body
        Bucket: awsConfig.bucketName,
        Key: fileName || file.name,
        Body: file,
        ContentType: file.type || "application/json",
        CacheControl: "max-age=3600"
    };

    return await s3Client.send(new PutObjectCommand(uploadParams))
}

/** Get file from s3.
 * @param {S3Client} s3Client 
 * @param {string} fileKey 
 * @returns {import("@aws-sdk/client-s3").GetObjectCommandOutput}
 */
export async function getFromS3Core(s3Client, fileKey) {
    /** 
     * @type {import("@aws-sdk/client-s3").GetObjectCommandInput}
     */
    const getParams = {
        // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-s3/interfaces/getobjectcommandinput.html
        Bucket: awsConfig.bucketName,
        Key: fileKey,
        ResponseCacheControl: "no-store"
    };

    return await s3Client.send(new GetObjectCommand(getParams))
}

/** To make function with rolling s3Client of given age. 
 * It means s3Client will be reused if request comes again in specified time, 
 * Which is supplied by {ageInSecond}
 * @param {number} ageInSecond Age of internal s3Client object
 * @param {function} functionToCall Function that will use s3Client as first arg
 * @return {function}
 */
function agedS3Wrapper(ageInSecond, functionToCall) {
    let intervalId = null
    let s3Client = null

    return async (...args) => {
        // stop interval to destroy s3 client if it exists
        if (!s3Client) s3Client = createS3Client()
        const response = await functionToCall(s3Client, ...args);

        if (intervalId) clearTimeout(intervalId)
        intervalId = setTimeout(() => {
            // destroy S3Client
            s3Client.destroy()
            s3Client = null
        }, ageInSecond * 1000);

        return response
    }
}

/** To upload files to S3 bucket
 * @callback  uploadToS3
 * @param {File} file 
 * @param {string} fileName 
 */
/** 
 * @type {uploadToS3} */
export const uploadToS3 = agedS3Wrapper(10, uploadToS3Core)

/** To upload files to S3 bucket
 * @callback  getFromS3
 * @param {string} fileKey 
 */
/** 
 * @type {getFromS3} */
export const getFromS3 = agedS3Wrapper(10, getFromS3Core)


/** To get json data from response given from s3client getobject
 * @param {ReadableStream} stream 
 * @returns {Promise<object>}
 */
export async function streamToJson(stream) {
    const reader = stream.getReader();
    const data = await reader.read();
    const buffer = Buffer.from(data.value);
    // console.log(buffer.toString())
    return await JSON.parse(buffer.toString());
}


/** To copy some thing to clipboard
 * @param {string} line 
 */
export function copyToClipboard(line) {
    console.log({ line })
    return navigator.clipboard.writeText(line)
}


/** To redirect tab to given path in this domain */
export function redirectTo(path) {
    if (path[0] !== '/') path = `/${path}`; // adding / in-front if not

    window.location.assign(`${window.location.origin}${path}`) // going to given url
}

/** To open given path in current domain in new tab */
export function openInNewTab(path) {
    if (path[0] !== '/') path = `/${path}`; // adding / in-front if not

    window.open(`${window.location.origin}${path}`, "_blank") // open in new tab
}

/** To create cookie setter and use is to set cookies */
function createCookieSetter() {
    const cookies = new Cookies()
    return {
        /**
         * @param {string} name 
         * @param {string} value 
         * @param {number} maxAgeSecond
         */
        setCookie: function (name, value, maxAgeSecond = 3600) {
            cookies.set(name, value, {
                path: '/',
                secure: isProdEnv(),
                // httpOnly: isProdEnv(), // Will not work as we have to get cookies with javascript
                sameSite: "strict",
                maxAge: (maxAgeSecond - 60)
            });
        }
    }
}

/** To set credential cookies
 * @param {import("../../types").CookieInfo} CookieInfo 
 */
export function setCredentialCookies(CookieInfo) {
    const cookieSetter = createCookieSetter()
    const maxAge = CookieInfo.Duration
    const deadLine = Date.now() / 1000 + maxAge
    cookieSetter.setCookie("AccessKeyId", CookieInfo.AccessKeyId, maxAge)
    cookieSetter.setCookie("SecretAccessKey", CookieInfo.SecretAccessKey, maxAge)
    cookieSetter.setCookie("SessionToken", CookieInfo.SessionToken, maxAge)
    cookieSetter.setCookie("SessionDeadLine", deadLine.toString(), maxAge) // all in seconds
}

/**
 * It will return functions that we can use to get differnt values
 * of session age
 */
export function createCookieDeadLineGetter() {
    const cookie = new Cookies()
    return {
        /**
         * To get Abs dead line in seconds
         * @returns {number}
         */
        getAbsDeadLine: function () {
            return parseInt(cookie.get("SessionDeadLine"))
        },
        getDiffDeadLine: function () {
            const diffS = (this.getAbsDeadLine() - new Date() / 1000);

            if (diffS >= 0) {
                return {
                    seconds: (Math.floor(diffS % 60)),
                    minutes: (Math.floor((diffS / 60) % 60)),
                    hours: (Math.floor((diffS / 60 / 60) % 24))
                }
            } else {
                return null
            }
        }
    }
}

/** 
 * To remove Credential cookies 
 */
export function unsetCredentialCookies() {
    const cookies = new Cookies()
    let names = ["AccessKeyId", "SecretAccessKey", "SessionToken", "SessionDeadLine"]
    names.forEach(name => cookies.remove(name))
}


/**
 * To get credential tokens from cookie
 *  @returns {import("../../types").AWSTokens} */
function getCredentialCookies() {
    const cookies = new Cookies()
    return {
        AccessKeyId: cookies.get("AccessKeyId"),
        SecretAccessKey: cookies.get("SecretAccessKey"),
        SessionToken: cookies.get("SessionToken"),
    }
}

/**
 * To generate aws client credentials from cookies
 * @return {import("@aws-sdk/client-sts").Credentials | null}
 * @see {@link getCredentialCookies} 
 */
function generateAwsCredential() {
    const {
        AccessKeyId: accessKeyId,
        SecretAccessKey: secretAccessKey,
        SessionToken: sessionToken
    } = getCredentialCookies()

    if (accessKeyId && secretAccessKey && sessionToken) {
        return { accessKeyId, secretAccessKey, sessionToken }
    }
    return null
}

/**
 * To verify if aws tokens in cookies are still valid
 */
export async function validateCredentialCookies() {
    try {

        const awsCredentials = generateAwsCredential()

        if (!awsCredentials) return false;

        const stsClient = new STSClient({
            region: "ap-south-1",
            credentials: awsCredentials
        })

        const getIdentitycommand = new GetCallerIdentityCommand()

        const response = await stsClient.send(getIdentitycommand)

        // TODO : remove
        // console.log({ checkSessionStatusCode: response.$metadata.httpStatusCode }) // Will give integer code

        return response.$metadata.httpStatusCode === 200
    } catch (err) {
        return false;
    }
}




//////////////////////////////////////////
//////////////////REMOVE/////////////////
// function createS3FileUploader(maxAgeInSecond) {
//     let destroyIntervalId = null
//     let s3Client = null

//     return (file, fileName = undefined) => {
//         // stop interval to destroy s3 client if it exists
//         if (destroyIntervalId) clearTimeout(destroyIntervalId)

//         /**
//              * @type {import("@aws-sdk/client-s3").PutObjectCommandInput}*/
//         const uploadParams = {
//             // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-s3/modules/putobjectrequest.html#body
//             Bucket: awsConfig.bucketName,
//             Key: fileName || file.name,
//             Body: file,
//             ContentType: file.type || "application/json",
//             CacheControl: "max-age=3600"
//         };

//         if (!s3Client) s3Client = createS3Client()
//         let response = await s3Client.send(new PutObjectCommand(uploadParams))

//         destroyIntervalId = setTimeout(() => {
//             // destroy S3Client
//             s3Client.destroy()
//             s3Client = null
//         }, maxAgeInSecond * 1000);

//         return response;
//     }
// }

