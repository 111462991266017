import { setCredentialCookies, unsetCredentialCookies, validateCredentialCookies } from "."
import { authApiUrl } from "../constants/urls"

/**
 * To login
 * @param {string} username 
 * @param {string} password 
 */
export async function login(username, password, logoutOnWrong = true) {
    const body = {
        username,
        password,
        task: "LOGIN"
    }

    /** @type {RequestInit} */
    const fetchOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json"
        }
    }

    /** @type {import("../../types").AWSApiResponse} */
    const response = await (
        await fetch(authApiUrl, fetchOptions)
    ).json()

    switch (response["statusCode"]) {
        case 200: {
            // Authenticated

            /** @type {import("../../types").AWSAuthenticatedBody} */
            const resBody = response.body

            // setting cookies with maxAge in seconds
            setCredentialCookies(resBody)

            return AuthStates.LOGIN_SUCCESS
        }
        case 401: {
            // Wrong credentials

            // logout
            if (logoutOnWrong) await logout()

            return AuthStates.INVALID_CRED
        }
        case 400:
        default: {
            // Other issue

            // logout
            await logout()

            return AuthStates.OTHER_ISSUE
        }
    }
}

/**
 * @returns Any Value from []./AuthStates]
 */
export async function logout() {
    // Unset cookie
    unsetCredentialCookies()

    // TODO : delete S3 client 
    return AuthStates.LOGOUT_SUCCESS
}

export async function validateLogin() {
    const stillValid = await validateCredentialCookies()
    if (!stillValid) {
        await logout()
        return false
    }
    return true
}

export const AuthStates = {
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    INVALID_CRED: "INVALID_CRED",
    OTHER_ISSUE: "OTHER_ISSUE"
}
Object.freeze(AuthStates);
