import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { homePagePath } from '../constants/urls';
import { redirectTo } from '../utils';
import { login, AuthStates, validateLogin } from '../utils/authenticator';

export default function Login({ appEnabler: loginCallback }) {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errorText, setErrorText] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(() => {
        // try  checking for existing valid passwords on starting only
        const checkExistingSession = async () => {
            setBtnLoading(true)
            const hasSession = await validateLogin()
            if (hasSession) {
                loginSuccessCallback()
            }
            setBtnLoading(false)
        }

        /**
         * We will look for "refreshSession" key in localstorage
         * and if we fount it with value YES then only we will 
         * not  attemp to use previous session to login again
         */
        if (localStorage.getItem("refreshSession") !== "YES") {
            checkExistingSession()
        } else {
            console.log("heelo")
            window.addEventListener(
                "beforeunload", (event) => {
                    localStorage.removeItem("refreshSession")
                }
            )
        }
    }, [])

    // used when login is success
    const loginSuccessCallback = () => {
        if (loginCallback && typeof loginCallback === "function") loginCallback()
        else redirectTo(homePagePath)
    }

    const handleUsernameChange = (event) => {
        let target = event.target
        setUsername(target.value)
    }


    const handlePasswordChange = (event) => {
        let target = event.target
        setPassword(target.value)
    }

    const handleLoginClick = async () => {
        setBtnLoading(true)

        let refreshSession = localStorage.getItem("refreshSession")
        let res = await login(username, password, refreshSession !== "YES")
        switch (res) {
            case AuthStates.LOGIN_SUCCESS: {
                /**
                 * On success remove "refreshSession" from local storage
                 * and close the window if refreshSession === YES
                 */
                localStorage.removeItem("refreshSession")
                if (refreshSession === "YES") {
                    window.close()
                }
                loginSuccessCallback()
                break;
            }
            case AuthStates.INVALID_CRED: {
                setErrorText("Invalid Username/Password")
                break;
            }
            case AuthStates.OTHER_ISSUE:
            default: {
                setErrorText("Unknown error occured")
                break;
            }
        }
        setPassword("")
        setBtnLoading(false)
    }

    const toggleShowPassword = () => {
        setShowPassword(oldState => !oldState)
    }


    return (
        <div className='login-wrapper'>
            <Paper className='login-div' elevation={12}>
                <h1>LOGIN</h1>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={username}
                    onChange={handleUsernameChange}
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                />

                <FormControl variant="outlined">
                    <InputLabel htmlFor="password" required>Password</InputLabel>
                    <OutlinedInput
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        required
                        onChange={handlePasswordChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword}
                                    onMouseDown={(event) => event.preventDefault()}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>

                <LoadingButton
                    className='submit-btn'
                    variant="contained"
                    onClick={handleLoginClick}
                    onClickCapture={(e) => e.preventDefault()}
                    loading={btnLoading}

                >Login
                </LoadingButton>
                <p className='error-text'>{errorText}</p>
            </Paper>
        </div >
    )
}