import React, { Component } from 'react'
import TransferList from './generic/TransferList';
import LoadingButton from '@mui/lab/LoadingButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getFileContent, setFileContent } from '../constants/fileValues';
import { imgList, imgListTop6 } from "../constants/fileNames";


export default class SelectTopPhotos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            /** @type {Array<string>} */
            left: [],
            /** @type {Array<string>} */
            right: [],
            /** @type {Array<string>} */
            checked: [],
            message: ""
        }
    }

    /**
     * @param {Array<string>} val 
     */
    setLeft(val) {
        this.setState({ left: val })
    }

    /**
     * @param {Array<string>} val 
     */
    setRight(val) {
        this.setState({ right: val })
    }

    /**
     * @param {string} val 
     */
    setChecked(val) {
        this.setState({ checked: val })
    }


    fetchImgLists() {
        let fetchPromises = Promise.all([
            getFileContent(imgList),
            getFileContent(imgListTop6)
        ])

        fetchPromises.then(
            /**
             * @param {Array<Array<string>} param0 
             */
            ([all, top6]) => {
                /** @type {Set<string>} */
                let top6Set = new Set();
                top6.forEach(ele => top6Set.add(ele));

                // now only keep values of (all-top6)
                all = all.filter(ele => !top6Set.has(ele));

                this.setState(_ => ({
                    left: [...all],
                    right: [...top6]
                }))
            })
    }

    componentDidMount = this.fetchImgLists

    saveTopImgFileList() {
        let list = this.state.right;
        if (list.length > 0) {
            this.setState({ uploading: true, message: "" });
            setFileContent(imgListTop6, list)
                .then(this.setState({ uploading: false, message: "Upload Success" }))
                .then(_ => this.fetchImgLists())
                .catch(err => this.setState({ message: "Upload Failed" + err }))
        }
    }

    render() {
        return (
            <div className='select-top-photos-wrapper'>
                <div className='toolbar'>
                    <LoadingButton
                        // size="small"
                        color="primary"
                        onClick={this.saveTopImgFileList.bind(this)}
                        loading={this.state.uploading}
                        loadingPosition="start"
                        startIcon={<FileUploadIcon />}
                        variant="contained"
                    >
                        Save
                    </LoadingButton>

                    <span className='message'>{this.state.message}</span>
                </div>
                <div className='view'>
                    <TransferList
                        checked={this.state.checked}
                        setChecked={this.setChecked.bind(this)}
                        left={this.state.left}
                        setLeft={this.setLeft.bind(this)}
                        right={this.state.right}
                        setRight={this.setRight.bind(this)}
                    />
                </div>
            </div>
        )
    }
}
