import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

/** Extra addition imports start*/
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { baseUrl, imgDirPath } from '../../constants/urls';
/** Extra addition imports end */


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList({ checked, setChecked, left, setLeft, right, setRight }) {
    // const [checked, setChecked] = React.useState([]);
    // const [left, setLeft] = React.useState([0, 1, 2, 3]);
    // const [right, setRight] = React.useState([4, 5, 6, 7]);
    const [lastClicked, setLastClicked] = React.useState(null);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        setLastClicked(value);

        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight([...leftChecked, ...right]);
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items) => (
        <Paper sx={{ width: "100%", height: "100%", overflow: 'auto' }} elevation={12}>
            <List dense component="div" role="list" className="abc">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            divider
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    // disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    const buttonStyle = {
        my: 0.5,
        fontWeight: "1000",
        fontSize: "1.5em"
    }
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" height="90vh">
            <Grid item height="100%" xs={4.5}>{customList(left.sort())}</Grid>
            <Grid item height="100%" xs={3}>
                <Grid container direction="column" height="100%" alignItems="center" justifyContent="center"
                    sx={{ fontWeight: 1000 }}>
                    {
                        lastClicked && <Card>
                            <CardMedia
                                component="img"
                                image={`${baseUrl}${imgDirPath}${lastClicked}`}
                                alt="Image Preview"
                            >
                            </CardMedia>
                        </Card>
                    }
                    <Button
                        sx={buttonStyle}
                        variant="contained"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={buttonStyle}
                        variant="contained"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={buttonStyle}
                        variant="contained"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={buttonStyle}
                        variant="contained"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item height="100%" xs={4.5}>{customList(right)}</Grid>
        </Grid >
    );
}
