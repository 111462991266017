import React, { Component } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import './App.scss';
import 'react-virtualized/styles.css';
import Button from "@mui/material/Button"
import LogoutIcon from '@mui/icons-material/Logout';
import { Divider, Paper } from '@mui/material';
import { AuthStates, logout } from './utils/authenticator';
import { createCookieDeadLineGetter, openInNewTab, redirectTo } from './utils';

/**
 * @type {Array<{path: string, text: string}>}
 */
const linkMappings = [
  {
    "path": "upload-result-photos",
    "text": "Upload Result Photos"
  },
  {
    "path": "select-top-photos",
    "text": "Top Photos"
  },
  {
    "path": "arrange-result-photos",
    "text": "Arrange Result Photos"
  }
]

export default class App extends Component {
  constructor(props) {
    super(props)
    this.timerIntervalId = null
    this.state = {
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  }

  async performLogout() {
    const didLogout = await logout()
    if (didLogout === AuthStates.LOGOUT_SUCCESS) {
      redirectTo("/login")
    }
  }

  async refreshSession() {
    /**
     * Here we are setting refreshSession to YES
     * And when login page opens in new tab we will look for this
     * value and is we found it then only we will not try to 
     * login using previous session
     */
    localStorage.setItem("refreshSession", "YES");
    openInNewTab("/login")
  }

  componentDidMount() {
    this.deadLineGetter = createCookieDeadLineGetter();

    this.timerIntervalId = setInterval(() => {
      let res = this.deadLineGetter.getDiffDeadLine()

      if (res) {
        this.setState(res)
      } else {
        clearInterval(this.timerIntervalId)
        redirectTo("/login")
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timerIntervalId) clearInterval(this.timerIntervalId);
  }

  render() {
    return (
      <div className="wrapper-div">

        {/* Nevigations */}
        <Paper className="nav-panel" elevation={12}>
          <h1 className='heading'>Navigation</h1>
          <Divider />
          <section className="nav-controls">
            {/* names of all sections */}
            <section className='nav-links'>
              {
                linkMappings.map((e, i) =>
                  <NavLink key={i} className='link' to={e["path"]}>{e["text"]}</NavLink>)
                // When this NavLink is active it will add "active" class in it
              }
            </section>
            <section className="btns" >
              <Timer className="session-timer" values={this.state} />
              <Button
                className='session-btn refresh'
                variant='contained'
                onClick={this.refreshSession.bind(this)}
              >Refresh Login</Button>
              <Button
                className='session-btn logout'
                variant='contained'
                endIcon={<LogoutIcon />}
                onClick={this.performLogout.bind(this)}
              >Logout</Button>
            </section>
          </section>
        </Paper>

        {/* Page Views */}
        <div className='page-view'>
          {/* Put all actual  */}
          <Outlet />
        </div>
      </div >
    )
  }
}

function Timer({ values, className }) {
  const { hours, minutes, seconds } = values;
  className = className ? className : "";

  return (
    <div className={className}>
      <span className="hours">{(hours < 10 ? "0" : "") + hours}</span>:
      <span className="minutes">{(minutes < 10 ? "0" : "") + minutes}</span>:
      <span className="seconds">{(seconds < 10 ? "0" : "") + seconds}</span>
    </div>
  )
}