import React, { Component, createRef } from 'react'
import { uploadToS3 } from "../utils";
import { getFileContent, setFileContent } from '../constants/fileValues';
import { imgList } from '../constants/fileNames';
import { imgDirPath } from '../constants/urls';

export default class UploadResultPhotos extends Component {
    constructor(props) {
        super(props);
        this.inputRef = createRef();

        this.state = {
            /** @type {Array.<{fileData: File, uploaded: boolean}>}  */
            fileList: [],
            uploading: false,
            message: "",
            listUploadMessage: "",
            /**
             * Image list present in s3 
             * @type {Array.<string>}
             */
            imgList: [],
            /**
             * File names which are selected but already in the list or in s3 list
             * @type {Array<string>}
             */
            duplicateFileNames: [],
            showDuplicateFileWarning: false
        }

        /**
         * To hold names of selected + present in s3
         * @type {Set.<string>}
         */
        this.nameSet = new Set();
    }

    fetch_imgList() {
        getFileContent(imgList)
            .then(
                /**
                 * @param {Array<string>} content 
                 */
                content => {
                    this.setState(_ => ({ imgList: [...content] }));
                    content.forEach(eleName => this.nameSet.add(eleName))
                })
    }

    componentDidMount() {
        this.fetch_imgList();
    }

    handleAddPhotoClick() {
        this.inputRef.current.value = null;
        this.inputRef.current.click();
        this.setState(() => ({ message: "", listUploadMessage: "" }));
    }

    /**
     * @param {Event} e 
     */
    handleInputSubmit(e) {
        /** @type {HTMLInputElement} */
        let ele = e.target;

        /** @type {Array<File>} */
        let selectedFiles = [...ele.files];

        let duplicateFileNames = selectedFiles
            .filter(file => this.nameSet.has(file.name))
            .map(file => file.name);

        let newFiles = selectedFiles
            .filter(file => !this.nameSet.has(file.name))
            .map(file => {
                this.nameSet.add(file.name);
                return { fileData: file, uploaded: false }
            })

        this.setState((state) => (
            {
                fileList: [...newFiles, ...state.fileList],
                showDuplicateFileWarning: duplicateFileNames.length > 0,
                duplicateFileNames: duplicateFileNames
            }))
    }

    handleDeleteClick(index) {

        this.nameSet.delete(this.state.fileList[index].fileData.name);
        let front = this.state.fileList.slice(0, index);
        let back = this.state.fileList.slice(index + 1, this.state.fileList.length);
        this.setState(() => ({ fileList: [...front, ...back] }));
    }

    handleRemoveAll() {
        this.setState(() => ({ fileList: [] }))
    }

    handleUpload() {
        /**
         * It should upload photos
         * After upload update imgList and sync with s3
         */
        let totalCount = this.state.fileList.length;

        this.setState(_ => ({ uploading: true }));

        let promisses = this.state.fileList
            .map((ele, index, arr) => uploadToS3(ele.fileData, imgDirPath + ele.fileData.name).then(() => arr[index].uploaded = true))

        Promise.allSettled(promisses)
            .then(_ => {
                let failedFileList = this.state.fileList.filter(ele => !ele.uploaded);

                let successFileList = this.state.fileList.filter(ele => ele.uploaded);
                let successFileNameList = successFileList.map(ele => ele.fileData.name);

                let failedCount = failedFileList.length;
                let message = `Uploaded: ${totalCount - failedCount} | Failed: ${failedCount}`
                this.setState(_ => ({ fileList: failedFileList, uploading: false, message }));

                // create new imgList to upload
                let newImgList = [...successFileNameList, ...this.state.imgList];
                setFileContent(imgList, newImgList)
                    .then(_ => this.setState({ listUploadMessage: "Updated File List" }))
                    .then(_ => this.fetch_imgList())
                    .catch(_ => this.setState({ listUploadMessage: "File List Update Failed" }))
            });
    }

    closeWarning() {
        this.setState({
            showDuplicateFileWarning: false,
            duplicateFileNames: []
        })
    }

    render() {
        return (
            <div className='upload-result-photos-wrapper'>
                <div className='toolbar'>
                    {/* hidden Input field for upload*/}
                    <input type="file"
                        ref={this.inputRef}
                        onChange={this.handleInputSubmit.bind(this)}
                        multiple={true}
                        accept="image/gif, image/jpeg, image/png, image/svg+xml, .txt"
                        style={{ "display": "none" }} />
                    <button onClick={this.handleAddPhotoClick.bind(this)}>Add Photos</button>
                    <button onClick={this.handleRemoveAll.bind(this)}>Remove All</button>
                    <button onClick={this.handleUpload.bind(this)}
                        disabled={this.state.uploading}>{this.state.uploading ? "Uploading..." : "Upload"}</button>
                    <p className='message'>{this.state.message + "\t|\t" + this.state.listUploadMessage}</p>
                </div>
                <div className='view'>
                    {this.state.fileList.map((ele, index) => (
                        !ele.uploaded ? <FileElement key={index} name={ele.fileData.name}
                            onDeleteClick={this.handleDeleteClick.bind(this, index)} /> : null
                    ))}
                </div>
                {
                    this.state.showDuplicateFileWarning &&
                    <div className='warning-view'>
                        <div className='message-box'>
                            <button className='close' onClick={this.closeWarning.bind(this)}>&#10006;</button>
                            <div className='name-list'>
                                <p className='head'>Can't add below images. Already exists or selected.</p>
                                {this.state.duplicateFileNames.map((e, i) => <p className='entry' key={i}>{`(${i + 1}) ${e}`}</p>)}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const FileElement = ({ name, onDeleteClick }) => {
    return (
        <div className='file-element'>
            <span>{name}</span>
            <button className='delete-btn' onClick={onDeleteClick}>X</button>
        </div>
    );
}