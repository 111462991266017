/**
 * Only for Json files, text files
 * Not for images like objects
 */
import { imgListUrl, imgListTop6Url } from "./urls";
import { uploadToS3, getFromS3, streamToJson } from "../utils";
import mockFileValues from "../mocked/fileValues";
import { imgList, imgListTop6 } from "./fileNames";
import isProdEnv from "./isProdEnv";

const fileValues = {};

// Use dev values or prod values
if (!isProdEnv()) {
    // development build code, assign mock values if prod environment
    Object.assign(fileValues, mockFileValues);
} else {
    // production build code
}

/** @param {string} fileName */
function getS3KeyForFile(fileName) {
    switch (fileName) {
        case imgList:
            return imgListUrl;
        case imgListTop6:
            return imgListTop6Url;
        default:
            return new Error("Wrong file name");
    }
}


/**
 * Get file from s3
 * @param {string} fileName 
 * @returns {Promise<object>}
 */
export async function getFileContent(fileName) {
    if (fileValues.hasOwnProperty(fileName)) {
        // return existing value but with promise for consistance on next block
        return new Promise((resolve, reject) => {
            resolve(fileValues[fileName]);
        })
    } else {
        // fetch from s3
        const res = await getFromS3(getS3KeyForFile(fileName));
        const data = await streamToJson(res.Body);
        fileValues[fileName] = data;
        return data;
    }
}

/**
 * Put object in s3
 * @param {string} fileName 
 * @param {string|File|Buffer|import("../types/json").json|Array} content 
 * @returns {Promise<import("@aws-sdk/client-s3").PutObjectCommandOutput>}
 */
export async function setFileContent(fileName, content) {
    let s3Key = getS3KeyForFile(fileName);

    const res = await uploadToS3(JSON.stringify(content), s3Key);
    fileValues[fileName] = content;
    return res;
}
