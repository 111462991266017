import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Draggable from 'react-draggable';

import { imgList as imgListName, imgListTop6 as topImgListName } from '../constants/fileNames';
import { getFileContent, setFileContent } from '../constants/fileValues';
import { copyToClipboard } from '../utils';
import { baseUrl, imgDirPath } from '../constants/urls';

export default class ArrangeResultPhotos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgList: [],
            uploadingImgList: false,
            uploadImgListMessage: "",
            topImgList: [],
            uploadingTopImgList: false,
            uploadTopImgListMessage: "",
        }
    }

    fetchImgList() {
        getFileContent(imgListName)
            .then(data => this.setState(_ => ({ imgList: data })));
    }
    fetchTopImgList() {
        getFileContent(topImgListName)
            .then(data => this.setState(_ => ({ topImgList: data })));
    }
    fetchBothFiles() {
        // get both list values
        this.fetchImgList()
        this.fetchTopImgList()
    }

    uploadImgList() {
        this.setState({ uploadingImgList: true, uploadImgListMessage: "" });
        setFileContent(imgListName, this.state.imgList)
            .then(_ => this.setState({ uploadImgListMessage: "Upload Successfull" }))
            .then(_ => this.fetchImgList())
            .catch(err =>
                copyToClipboard(err)
                    .then(_ => this.setState({ uploadImgListMessage: "Upload Failed. Paste Error to notes." }))
            )
            .finally(_ => this.setState({ uploadingImgList: false }))

    }

    uploadTopImgList() {
        this.setState({ uploadingTopImgList: true, uploadTopImgListMessage: "" });
        setFileContent(topImgListName, this.state.topImgList)
            .then(_ => this.setState({ uploadTopImgListMessage: "Upload Successfull" }))
            .then(_ => this.fetchTopImgList())
            .catch(err =>
                copyToClipboard(err)
                    .then(_ => this.setState({ uploadTopImgListMessage: "Upload Failed. Paste Error to notes." }))
            )
            .finally(_ => this.setState({ uploadingTopImgList: false }))
    }

    componentDidMount = this.fetchBothFiles

    setImgList(imgList) {
        this.setState(_ => ({ imgList }))
    }

    setTopImgList(topImgList) {
        this.setState(_ => ({ topImgList }))
    }

    render() {
        return (
            <div className='arrange-result-photos-wrapper'>
                <Paper className='whole-list' elevation={12}>
                    <ManagingList
                        heading='All Images'
                        nameList={this.state.imgList}
                        setNames={this.setImgList.bind(this)}
                        uploadNames={this.uploadImgList.bind(this)}
                        uploading={this.state.uploadingImgList}
                        message={this.state.uploadImgListMessage}
                    />
                </Paper>
                <Paper className='top-list' elevation={12}>
                    <ManagingList
                        heading='Top Images'
                        nameList={this.state.topImgList}
                        setNames={this.setTopImgList.bind(this)}
                        uploadNames={this.uploadTopImgList.bind(this)}
                        uploading={this.state.uploadingTopImgList}
                        message={this.state.uploadTopImgListMessage}
                    />
                </Paper>

            </div>
        )
    }
}


const buttonStyle = {
    my: 1,
    fontWeight: "1000",
    fontSize: "1.5em"
}
function swapItemsInArray(arr, index1, index2) {
    let temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
}

function ManagingList({ heading, nameList, setNames, uploadNames, uploading, message }) {
    const [checkedIndex, setChecked] = React.useState(0);

    const moveUp = () => {
        // update array and change checked index
        if (checkedIndex > 0) {
            let newNameList = [...nameList];
            // swap i and i-1 element
            swapItemsInArray(newNameList, checkedIndex, checkedIndex - 1);
            // update state of parent
            setNames(newNameList);
            // update checked index
            setChecked(checkedIndex - 1);
        }
    }

    const moveDown = () => {
        // update array and change checked index
        if (checkedIndex < nameList.length - 1) {
            let newNameList = [...nameList];
            // swap i and i+1 element
            swapItemsInArray(newNameList, checkedIndex, checkedIndex + 1);
            // update state of parent
            setNames(newNameList);
            // update checked index
            setChecked(checkedIndex + 1);
        }
    }

    return (
        <div className='managing-list'>
            {/* Combination of headings, list and motion buttons */}
            <div className='btn-div'>
                {/* Heading part >> Heading and save button */}
                <span>{message}</span>
                <h1>{heading}
                    <LoadingButton className='btn'
                        // size="small"
                        color="primary"
                        onClick={uploadNames}
                        loading={uploading}
                        loadingPosition="start"
                        startIcon={<FileUploadIcon />}
                        variant="contained"
                    >
                        Save
                    </LoadingButton>
                </h1>
            </div>

            <div className='view'>
                {/* Combination of list and motion buttons */}
                <div className='list-div'>
                    {/* Name list */}
                    <List dense component="div" role="list">
                        {
                            nameList.map((value, index) => (
                                <ListItem
                                    key={index}
                                    role="listitem"
                                    button
                                    divider
                                    onClick={() => setChecked(index)}
                                >
                                    <ListItemIcon>
                                        <Radio
                                            checked={index === checkedIndex}
                                            name={heading}
                                            value={index}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={value} primary={value} />
                                </ListItem>
                            ))
                        }
                    </List>
                </div>

                <div className='nav-btn-div'>
                    {/* All motion buttons */}
                    <IconButton sx={buttonStyle} color="primary"
                        size='large' className='move-btn' onClick={moveUp}>
                        <ArrowUpwardIcon fontSize='large' />
                    </IconButton>
                    <IconButton sx={buttonStyle} color="primary"
                        size='large' className='move-btn' onClick={moveDown}>
                        <ArrowDownwardIcon fontSize='large' />
                    </IconButton>
                </div>

                <Draggable
                    handle=".handle"
                    defaultPosition={{ x: 0, y: 0 }}
                    position={null}
                    grid={[2, 2]}
                    scale={1}
                    bounds=".view"
                >
                    <Card className='img-preview'>
                        <div className='handle'>MOVE</div>
                        <CardMedia
                            component="img"
                            image={`${baseUrl}${imgDirPath}${nameList[checkedIndex]}`}
                            alt="Image Preview"
                        >
                        </CardMedia>
                        <div className='handle'>MOVE</div>
                    </Card>
                </Draggable>
            </div>
        </div>
    );
}   