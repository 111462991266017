import isProdEnv from "./isProdEnv";

// TODO: remove credentials
const awsConfig = {
    "bucketName": "testing-homoeopathy-forever-website",
    "region": "ap-south-1"
};

if (isProdEnv()) {
    // change bucket name to target bucket in production
    awsConfig.bucketName = "homoeopathy-forever-website"
}

export default awsConfig;